<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_smsTemplate") }}
            </b-card-title>
            <!-- <b-card-sub-title class="mt-1">
              {{ lang("t_extensionRoutes") }}
            </b-card-sub-title> -->
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <b-button
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
                <b-icon icon="funnel"></b-icon>
              </b-button>
            </b-card-text>
            <b-table
              :filter="searchQuery"
              small
              hover
              :fields="tableColumns"
              :items="records"
              responsive
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="records.length == 0"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <template #cell(project)="data">
                {{
                  projects.find((e) => e.internal_name == data.item.project)
                    .display_name
                }}
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  boundary="viewport"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    records.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="records.length"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_records"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Project -->
          <validation-provider #default="validationContext" name="Project">
            <b-form-group :label="lang('t_project')" label-for="project_name">
              <v-select
                v-model="currentData.project"
                :options="projects"
                :placeholder="lang('t_project')"
                label="display_name"
                :reduce="(val) => val.internal_name"
                @input="get_columns"
              >
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Template Name -->
          <validation-provider
            #default="validationContext"
            :name="lang('t_patternName')"
          >
            <b-form-group
              :label="lang('t_patternName')"
              label-for="template_name"
            >
              <b-form-input
                id="template_name"
                v-model="currentData.display_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div>
            <draggable
              :list="columns"
              :group="{ name: 'g1', pull: 'clone', put: false }"
              :clone="cloneItem"
            >
              <b-badge
                style="width: 5.2vw"
                class="mr-50 mb-50 badge-glow cursor-move"
                v-for="(item, i) in columns"
                :key="i"
                variant="primary"
              >
                {{ item.display_name }}
              </b-badge>
            </draggable>
          </div>

          <!-- Message Text -->
          <validation-provider #default="validationContext" name="Message">
            <b-form-group :label="lang('t_smsText')" label-for="message_text">
              <draggable
                class="dragArea list-group card"
                tag="ul"
                :list="list2"
                :group="{ name: 'g1', type: 'voice_export' }"
                @add="changeItemId"
              >
                <b-form-group>
                  <b-form-textarea
                    id="message_text"
                    v-model="currentData.message"
                    :state="getValidationState(validationContext)"
                    trim
                    rows="3"
                  />
                </b-form-group>
              </draggable>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{lang('t_add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{lang('t_cancel')}}
            </b-button>
          </div>
          -->
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BSidebar,
  BFormTextarea,
  BCardText,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import draggable from "vuedraggable";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // UsersListFilters,
    draggable,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSidebar,
    BFormTextarea,
    BCardText,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,

    vSelect,
  },
  computed: {
    items() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.records.slice(start, end);
    },

  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      isEdit: false,
      currentData: {
        display_name: "",
        message: "",
        project: "",
        internal_name: "",
      },
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      tableColumns: [
        {
          key: "project",
          label: globalThis._lang("t_project"),
          sortable: true,
        },
        {
          key: "display_name",
          label: globalThis._lang("t_patternName"),
          sortable: true,
        },
        {
          key: "message",
          label: globalThis._lang("t_smsText"),
          sortable: true,
        },
        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { widht: '5vh' },
        },
      ],
      column_labels: {
        customer_no: globalThis._lang('t_customerNo'),
        customer_name: globalThis._lang('t_customerName'),
        customer_identifier: globalThis._lang('t_customerIdentifier'),
        queue: globalThis._lang('t_queue'),
        active: globalThis._lang('t_state'),
        status: globalThis._lang('t_status'),
        assigned_agent: globalThis._lang('t_agent'),
        attempts: globalThis._lang('t_attempts'),
        total_attempts: globalThis._lang('t_totalAttempts'),
        campaign: globalThis._lang('t_campaign'),
        finish_code: globalThis._lang('t_finishCode'),
        insert_date: globalThis._lang('t_insertDate'),
        action_date: globalThis._lang('t_actionDate'),
        action_time: globalThis._lang('t_actionTime'),
        create_date: globalThis._lang('t_createDate'),
        create_time: globalThis._lang('t_createTime'),
        ex_agent: globalThis._lang('t_exAgent'),
        assign_date: globalThis._lang('t_assignDate'),
        new_assets: globalThis._lang('t_newAssets'),
        reached: globalThis._lang('t_reached'),
        reached_date: globalThis._lang('t_reachedDate'),
      },
      columns: [],
      list2: [],
      records: [],
      projects: [],
    };
  },
  mounted: function () {
    this.get_records();
    this.get_projects();
  },
  methods: {
    cloneItem(item) {
      // console.log(item);
      this.currentData.message += ` {{${item.internal_name}}} `;
      // return { ...item, tasks: [], tasks2: [] };
    },
    get_projects: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;
      this.projects = response;
    },
    get_columns: async function () {
      this.columns = [];
      var response = (
        await this.$http_in.get(
          `spv/v1/ProjectColumns/${this.currentData.project}_customers`
        )
      ).data;
      for (const item of response[0].allkeys) {
        if (
          [
            "active",
            "_id",
            "cycles",
            "customer_identifier_masked",
            "notes",
            "phones",
            "products",
            "campaign",
            "sms",
            "extra_data",
            "daily_attempts",
            "update_date",
            "assigned_agent2",
          ].includes(item)
        ) {
          continue;
        }
        this.columns.push({
          internal_name: item,
          display_name:
            this.column_labels[item] == undefined
              ? item
              : this.column_labels[item],
        });
      }
    },
    get_records: async function () {
      var response = (await this.$http_in.get(`spv/v1/SmsTemplate`)).data;
      this.records = response;
    },
    check_data() {
      if (
        [null, undefined, ""].includes(this.currentData.project) ||
        this.currentData.display_name == "" ||
        this.currentData.message == ""
      ) {
        return true;
      }
      return false;
    },

    add_records: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(`spv/v1/SmsTemplate`, {
            TemplateInfo: JSON.stringify(this.currentData),
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`spv/v1/SmsTemplate`, {
            TemplateInfo: JSON.stringify(this.currentData),
          })
        ).data;
      }
      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      try {
        var response = (
          await this.$http_in.delete(`spv/v1/SmsTemplate/${this.currentData.internal_name}`)
        ).data;

        if (response != null || response != undefined) {
          this.$swal({
            icon: "success",
            title: this.lang("t_processSuccess"),
            text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
            confirmButtonText: this.lang("t_ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      }
      catch (error) {
        if (error && error.response && error.response.data) {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: `${error.response.data} kullanıldığı için kayıt silinemiyor.`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } finally {
        this.is_progress = false;
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        display_name: "",
        message: "",
        project: "",
        internal_name: "",
      };
    },
    updateSelected(item) {
      this.currentData = item;
      console.log("item", item);
      this.isEdit = true;
      // this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const groupOptions = [
      { label: "Grup1", value: "123" },
      { label: "Grup4", value: "456" },
      { label: "Grup3", value: "9999" },
      { label: "Grup2", value: "654" },
    ];
    const classificationOptions = [
      { label: "5XX", value: "123" },
      { label: "905XX", value: "456" },
      { label: "5105XX", value: "9999" },
      { label: "5405XX", value: "654" },
      { label: "isnet", value: "654" },
    ];
    const extensOptions = [
      { label: "123", value: "123" },
      { label: "456", value: "456" },
      { label: "9999", value: "9999" },
      { label: "654", value: "654" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "1" },
      { label: "Profil2", value: "2" },
      { label: "Profil3", value: "3" },
      { label: "Profil4", value: "4" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      groupOptions,
      classificationOptions,
      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
